import { Component } from '@angular/core';
import {  ElementRef, AfterViewInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements AfterViewInit{
  private margin = { top: 20, right: 20, bottom: 30, left: 40 };
  private width = 500 - this.margin.left - this.margin.right;
  private height = 300 - this.margin.top - this.margin.bottom;

  private data = [
    { label: 'Globos', value: 10 },
    { label: 'Banderas', value: 20 },
    { label: 'Globos grandes', value: 30 },
    { label: 'Globos chicos', value: 40 },
    { label: 'Mascaras', value: 50 },
    { label: 'Carioca', value: 50 }
  ];

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    this.createChart();
  }

  private createChart(): void {
    const element = this.elementRef.nativeElement.querySelector('.chart');
    const svg = d3.select(element)
      .append('svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    const xScale = d3.scaleBand()
      .domain(this.data.map(d => d.label))
      .range([0, this.width])
      .padding(0.2);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(this.data, d => d.value) as number])
      .nice()
      .range([this.height, 0]);

    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale);

    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${this.height})`)
      .call(xAxis);

    svg.append('g')
      .attr('class', 'y axis')
      .call(yAxis);

    const bars = svg.selectAll('.bar')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', d => xScale(d.label) as number)
      .attr('y', d => yScale(d.value))
      .attr('width', xScale.bandwidth())
      .attr('height', d => this.height - yScale(d.value))
      .attr('fill', 'steelblue')
      .on('mouseover', function(event, d) {
        d3.select(this)
          .attr('fill', 'orange');
      })
      .on('mouseout', function(event, d) {
        d3.select(this)
          .attr('fill', 'steelblue');
      });

    // Add labels
    svg.selectAll('.label')
      .data(this.data)
      .enter()
      .append('text')
      .attr('class', 'label')
      .attr('x', d => (xScale(d.label) as number) + xScale.bandwidth() / 2)
      .attr('y', d => yScale(d.value) - 5)
      .attr('text-anchor', 'middle')
      .text(d => d.value)
      .attr('fill', 'black');
  }
}