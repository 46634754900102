import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { CrearRemitoComponent } from '../pintura-factory/pages/inicio/crear-remito/crear-remito.component';
import { CrearReciboPagoComponent } from '../pintura-factory/pages/inicio/crear-recibo-pago/crear-recibo-pago.component';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss']
})
export class DashComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cardLayout: Observable<any>;

  miniCards = [
    { title: 'Entradas de Orden', value: '2,2 mil', change: '+1,0 mil', type: 'adoption' },
    { title: 'Entradas Remito', value: '1,6 mil', change: '+1,0 mil', type: 'engagement' },
    { title: 'Clientes', value: '215,0', change: '+1,0 mil', type: 'retention' },
    { title: 'Saldo Total', value: '4,8', change: '+1,0 mil', type: 'success' }
  ];
  
  constructor(private breakpointObserver: BreakpointObserver, private router: Router, 
    private loginService: LoginService, public dialog: MatDialog ) {
    this.cardLayout = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet]).pipe(
      map(result => {
        const breakpoints = result.breakpoints;
        if (breakpoints[Breakpoints.Handset]) {
          return {
            columns: 1,
            miniCard: { cols: 1, rows: 1 },
            chart: { cols: 1, rows: 2 },
            table: { cols: 1, rows: 4 },
          };
        } else if (breakpoints[Breakpoints.Tablet]) {
          return {
            columns: 2,
            miniCard: { cols: 1, rows: 1 },
            chart: { cols: 2, rows: 2 },
            table: { cols: 2, rows: 4 },
          };
        } else {
          return {
            columns: 4,
            miniCard: { cols: 1, rows: 1 },
            chart: { cols: 2, rows: 2 },
            table: { cols: 4, rows: 4 },
          };
        }
      })
    );
  }
  
    ngOnInit() {
    }

    openCrearRemito() {
        const dialogRef = this.dialog.open(CrearRemitoComponent, {
            width: '1550px'
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result.event != 'Cancel'){
          }
        });
      }

      openCrearReciboDePago() {
        const dialogRef = this.dialog.open(CrearReciboPagoComponent, {
            width: '1550px'
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result.event != 'Cancel'){
          }
        });
      }
}
