import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class FacturaService {
  
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  public getFacturasEmitidas(): Observable<any> {
    console.log('entro al get');
    return this.http.get<any>(this.baseUrl+'/api/facturasLista/');
  }

  public getDetalleFacturas(id:number): Observable<any> {
    console.log('entro al get');
    return this.http.get<any>(this.baseUrl+'/api/facturas/' + id);
  }

  public postFacturar(factura :any ): Observable <any>{
    console.log("entro al post");
    return this.http.post(this.baseUrl + '/api/facturaParcial', factura, { responseType: 'blob' });
  }

  public postReimprimir(id :any ): Observable <any>{
    console.log("entro al post");
    return this.http.post(this.baseUrl + '/api/recibopago/', id, { responseType: 'blob' });
  }

  public postRecibo(recibo :any ): Observable <any>{
    console.log("entro al post");
    return this.http.post(this.baseUrl + '/api/crear-recibopago/', recibo, { responseType: 'blob' });
  }

  public reimprimirFactura(reimprimirDTO :any ): Observable <any>{
    console.log("entro al post");
    return this.http.post(this.baseUrl + '/api/reimprimir',reimprimirDTO, { responseType: 'blob' } );
  }

  public postFacturaParcial(factura: any): any{
    console.log ("entro al post");
    return this.http.post(this.baseUrl + '/api/facturaParcial', factura, { responseType: 'blob' });
  }

  public postRemito(remito: any): any{
    console.log ("entro al post");
    return this.http.post(this.baseUrl + '/api/crear-remito', remito, { responseType: 'blob' });
  }

  public postFacturaTipoRemito(factura: any): any{
    console.log ("entro al post");
    return this.http.post(this.baseUrl + '/api/facturaTipoRemito', factura, { responseType: 'blob' });
  }

  public putFacturasPagas(id: number): Observable <any>{
    console.log ("entro al put");
    return this.http.put<any>(this.baseUrl + '/api/facturasPagas/'+ id, null);
  }
}
