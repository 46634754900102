<h1>Crear Remito</h1>
    <mat-divider></mat-divider>
    <br>
    <form [formGroup]="formGroup">
        <div [ngClass]="{'row': true}">
                        <mat-form-field>
                          <input matInput [(ngModel)]="datosCliente.razonSocial" placeholder="Razon Social" [formControl]="autocompleteControl"  [matAutocomplete]="auto" value="razonSocialCliente" required>
                          <mat-autocomplete #auto="matAutocomplete" (optionSelected)= "seleccionarRazonSocial($event)">
                              <mat-option *ngFor="let option of filtroOpcionesRazonSocial | async" [value]="option">
                                {{option.razonSocial}}
                              </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                        &nbsp;
                        &nbsp;
                        <mat-form-field>
                          <input matInput placeholder="Cuit" [(ngModel)]="cuit" formControlName="cuit" required/>
                        </mat-form-field>
                        &nbsp;
                        &nbsp;
                        <mat-form-field>
                          <input matInput placeholder="Producto" [(ngModel)]="items.nombreProducto.nombreProducto" formControlName="nombreProducto" required/>
                            <!-- <input matInput [(ngModel)]="items.nombreProducto" placeholder="Producto" [formControl]="autocompleteControlProducto"  [matAutocomplete]="autoProducto" value="nombreProduc" required>
                            <mat-autocomplete #autoProducto="matAutocomplete" [displayWith]="displayNombre" (optionSelected)= "seleccionarNombre($event)">
                                <mat-option *ngFor="let option of filtroOpcionesNombre | async" [value]="option">
                                  {{option.nombreProducto}}
                                </mat-option>
                            </mat-autocomplete> -->
                          </mat-form-field>
                          &nbsp;
                          &nbsp;
                          <mat-form-field>
                            <input matInput placeholder="Código/Descripción" [(ngModel)]="items.descripcion" formControlName="descripcion" required/>
                          </mat-form-field>
                          &nbsp;
                          &nbsp;
                          <mat-form-field>
                            <input matInput placeholder="Cantidad" [(ngModel)]="items.cantidad" type="number" formControlName="cantidad" required/>
                          </mat-form-field>
                          &nbsp;
                          &nbsp;
                          <mat-form-field>
                            <input matInput placeholder="Precio Unitario" [(ngModel)]="items.precioUnitario" type="number" formControlName="precioUnitario" required/>
                            <span matPrefix>$&nbsp;</span>
                          </mat-form-field>
                          &nbsp;
                          &nbsp;
                          <div class="contenido">
                            <!-- <mat-form-field>
                              <input matInput placeholder="Condición frente al IVA" [(ngModel)]="iva" type="text" formControlName="iva" required/>
                            </mat-form-field> -->
                            <mat-form-field>
                              <mat-select matNativeControl placeholder="Condición frente al IVA" formControlName="iva" [(ngModel)]="iva" required>
                                  <mat-option [value]="'Responsable Inscripto'">Responsable Inscripto</mat-option>
                                  <mat-option [value]="'No Categorizado'">No Categorizado</mat-option>
                                  <mat-option [value]="'Responsable Monotributista'">Responsable Monotributista</mat-option>
                                  <mat-option [value]="'Excento'">Excento</mat-option>
                                  <mat-option [value]="'No Responsable'">No Responsable</mat-option>
                                  <mat-option [value]="'Consumidor Final'">Consumidor Final</mat-option>
                              </mat-select>
                            </mat-form-field>
                            &nbsp;
                            &nbsp;
                            <button mat-raised-button color="primary" (click)="addNew()">
                              Cargar
                              <mat-icon>add</mat-icon>
                            </button>
                            </div>
        </div>
    </form>
    <br>
<mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!-- Id Column -->
  <!-- <ng-container matColumnDef="productoId">
    <mat-header-cell *matHeaderCellDef class="encabezado">N°</mat-header-cell>
    <mat-cell *matCellDef="let element" class="contenido">{{element.nombreProducto.productoId}}</mat-cell>
  </ng-container> -->

  <!-- Producto Column -->
  <ng-container matColumnDef="nombreProducto">
    <mat-header-cell *matHeaderCellDef class="encabezado">Producto</mat-header-cell>
    <mat-cell *matCellDef="let element" class="contenido">{{element.nombreProducto.nombreProducto}}</mat-cell>
  </ng-container>

  <!-- Código/Descripción Column -->
  <ng-container matColumnDef="descripcion">
    <mat-header-cell *matHeaderCellDef class="encabezado">Código/Descripción</mat-header-cell>
    <mat-cell *matCellDef="let element" class="contenido">{{element.descripcion}}</mat-cell>
  </ng-container>

  <!-- Cantidad Column -->
  <ng-container matColumnDef="cantidad">
    <mat-header-cell *matHeaderCellDef class="encabezado">Cantidad</mat-header-cell>
    <mat-cell *matCellDef="let element" class="contenido">{{element.cantidad}}</mat-cell>
  </ng-container>

  <!-- Precio Unitario Column -->
  <ng-container matColumnDef="precioUnitario">
    <mat-header-cell *matHeaderCellDef class="encabezado">Precio Unitario</mat-header-cell>
    <mat-cell *matCellDef="let element" class="contenido">{{element.precioUnitario| currency: 'USD':'symbol':'3.0' | lowercase }}</mat-cell>
  </ng-container>

  <!-- Total Column -->
  <ng-container matColumnDef="total">
    <mat-header-cell *matHeaderCellDef class="encabezado">Total</mat-header-cell>
    <mat-cell *matCellDef="let element" class="contenido">{{calcularTotal(element) | currency: 'USD':'symbol':'3.0' | lowercase }}</mat-cell>
  </ng-container>

  <!-- Acción Column -->
  <ng-container matColumnDef="accion">
    <mat-header-cell *matHeaderCellDef class="encabezado"> </mat-header-cell>
    <mat-cell *matCellDef="let element" class="contenido"> 
      <button mat-button (click)="borrarFila(element)" style="color:black; background-color: white">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>
  
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<br>
  <div class="contenido">
      <button mat-button (click)="cancel()" style="color:white; background-color: rgb(100, 100, 107)">Cancelar</button>
      &nbsp;
      <button mat-button (click)="nuevoRemito()" style="background-color: red; color:white">
        Generar Nuevo Remito
        <mat-icon>done</mat-icon>
      </button>
  </div>