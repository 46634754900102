import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ListadoProducto } from '../models/listadoProducto.model';
import { InsumoProducto, AsociarCliente, Insumo } from '../pintura-factory/pages/productos/cargar-producto/cargar-producto.component';
import { Nombre } from '../pintura-factory/pages/pedidos/nuevo-pedido/nuevo-pedido.component';

@Injectable({ providedIn: 'root' })
export class ProductosService {
  
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  public filtrarInsumo(condicion: string): Observable<InsumoProducto[]> {
    return this.http.get<InsumoProducto[]>(this.baseUrl + '/api/insumoNombreDesripcion/' + condicion);
  }

  public filtrarRazonSocial (condicion: string): Observable<AsociarCliente[]> {
    return this.http.get<AsociarCliente[]>(this.baseUrl + '/api/clienteRazonSocialCuit/' + condicion);
  }

  public filtrarAllProductos (condicion: string): Observable<Nombre[]> {
    return this.http.get<Nombre[]>(this.baseUrl + '/api/filtrarProductos/' + condicion);
  }

  public getListadoProducto(): Observable<any> {
    console.log('entro al get');
    return this.http.get<any>(this.baseUrl+'/api/movimiento-productoFinal');
  }

  public getObtenerPrecio(): Observable<any> {
    console.log('entro al get');
    return this.http.get<any>(this.baseUrl+'/api/cotizacion-precio');
  }

  public cargarProducto(productoFinal :any, idCliente:any ): Observable <any> {
    console.log('entro al post');
    return this.http.post<any>(this.baseUrl+'/api/producto-final', productoFinal);

  }

  public agregarInsumo(insumo :any): Observable <any> {
    console.log('entro al post');
    return this.http.put<any>(this.baseUrl+'/api/agregar-insumo', insumo);

  }

  public cotizarProducto(cotizacion: any):  Observable<any> {
    console.log('entro al post');

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' 
    });

    return this.http.post<any>(this.baseUrl+'/api/productoCotizacion', cotizacion, { headers: httpHeaders });

  }

  public getCatalogoProducto(): Observable<any> {
    console.log('entro al get');
    return this.http.get<any>(this.baseUrl+'/api/producto-final');
  }

  public getDetalleProducto(productoId: number): Observable<Insumo[]> {
    console.log('entro al get');
    return this.http.get<Insumo[]>(this.baseUrl + '/api/producto-final/detalle/' + productoId);
  }

  public delete(deleteFila: any): Observable<any> {
    console.log('entro al delete');
    console.log(deleteFila);
    return this.http.put<any>(this.baseUrl + '/api/delete/', deleteFila);
  }

  public putProductoFinalInsumo(productoFinal: any): Observable <any>{
    console.log ("entro al put");
    return this.http.put<any>(this.baseUrl + '/api/producto-final/actualizacion', productoFinal);
  }

  public putProductoFinal(producto: any): Observable <any>{
    console.log ("entro al put");
    return this.http.put<any>(this.baseUrl + '/api/producto-final', producto);
  }
}
