import { Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Usuario } from 'src/app/models/usuario.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { HomeComponent } from '../pintura-factory/pages/home/home.component';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

private user!: Usuario;
private token!: string;
private baseUrl: string =  'https://dev-fit.com/dacolor'; //environment.baseUrl;

private _authSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
public get isAuthenticated$(): Observable<boolean> {
    return this._authSub$.asObservable();
  }

  constructor(private http: HttpClient) { }


  login(usuario: Usuario): Observable<any> {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' 
    });

    let params = new URLSearchParams();
    params.set('username', usuario.username);
    params.set('password', usuario.password);
    console.log(params.toString());
    return this.http.post<any>(`${environment.baseUrl}/api/login`,  usuario, { headers: httpHeaders })
    .pipe(
      // Aquí puedes manejar la respuesta del servidor antes de guardarla en el localStorage
      tap(data => {
        // Verifica si la respuesta contiene datos de usuario (puede variar según tu API)
        if (data && data.token) {
          // Guarda los datos de usuario en el localStorage
          const rol = this.obtenerDatosToken(data.token);
          localStorage.setItem('ROL', JSON.stringify(rol.authorities));
          localStorage.setItem('USERNAME', JSON.stringify(data.username));

        }
      })
    );
  }

  obtenerUsername(): any {
    const USERNAME = localStorage.getItem('USERNAME');
    return USERNAME ? JSON.parse(USERNAME) : null;   
  }

  getUserData(): any {
    const ROL = localStorage.getItem('ROL');
    return ROL ? JSON.parse(ROL) : null;
  }

  logout(): void {
    sessionStorage.clear();
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
  }
  
  usuario(): Usuario {
    if (this.user != null) {
      return this.user;
    } else if (this.user == null && sessionStorage.getItem('usuario') != null) {
      return this.user;
    }
    return new Usuario();
  }

postNuevoUsuario(accessToken: string): void {
    let payload = this.obtenerDatosToken(accessToken);
    this.user = new Usuario();
    this.user.username = payload.username;
    sessionStorage.setItem('usuario', JSON.stringify(this.user));
  }
  
  obtenerDatosToken(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(atob(accessToken.split(".")[1]));
    }
    return null;
  }


  isAuthenticated(): boolean {
    let payload = this.obtenerDatosToken(this.token);
    if (payload != null && payload.username && payload.username.length > 0) {
      return true;
    }
    return false;
  }

}