<h1>Crear Recibo de Pago</h1>
    <mat-divider></mat-divider>
    <br>
    <form [formGroup]="formGroup">
        <div [ngClass]="{'row': true}">
                        <mat-form-field>
                          <input matInput [(ngModel)]="datosCliente.razonSocial" placeholder="Razon Social" [formControl]="autocompleteControl"  [matAutocomplete]="auto" value="razonSocialCliente" required>
                          <mat-autocomplete #auto="matAutocomplete" (optionSelected)= "seleccionarRazonSocial($event)">
                              <mat-option *ngFor="let option of filtroOpcionesRazonSocial | async" [value]="option">
                                {{option.razonSocial}}
                              </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                        &nbsp;
                        &nbsp;
                        <mat-form-field>
                          <input matInput placeholder="Cuit" [(ngModel)]="cuit" formControlName="cuit" required/>
                        </mat-form-field>
                        &nbsp;
                        &nbsp;
                          <mat-form-field>
                            <input matInput placeholder="N° de Factura" [(ngModel)]="nroFactura" formControlName="nroFactura" required/>
                          </mat-form-field>
                          &nbsp;
                          &nbsp;
                          <mat-form-field>
                            <input matInput placeholder="N° de Cheque" [(ngModel)]="nroCheque" type="number" formControlName="nroCheque" required/>
                          </mat-form-field>
                          &nbsp;
                          &nbsp;
                          <mat-form-field>
                            <input matInput placeholder="Total" [(ngModel)]="total" type="number" formControlName="total" required/>
                            <span matPrefix>$&nbsp;</span>
                          </mat-form-field>
                          
        </div>
    </form>
<br>
  <div class="contenido">
      <button mat-button (click)="cancel()" style="color:white; background-color: rgb(100, 100, 107)">Cancelar</button>
      &nbsp;
      <button mat-button (click)="nuevoRecibo()" style="background-color: red; color:white">
        Generar Nuevo Recibo de Pago
        <mat-icon>done</mat-icon>
      </button>
  </div>