<!-- <h1>Inicio</h1> -->
    <!-- <mat-divider></mat-divider> -->
    <br>
    <div class="button-container">
      <button mat-button (click)="openCrearRemito()" class="half-width-button">
        Crear Remito
        <mat-icon>receipt</mat-icon>
      </button>
      &nbsp;
      &nbsp;
      <button mat-button (click)="openCrearReciboDePago()" class="half-width-button">
        Crear Recibo de Pago
        <mat-icon>receipt</mat-icon>
      </button>
    </div>
<div class="grid-container">
  <!-- <h1 class="mat-h1">Dashboard</h1> -->

  <mat-grid-list cols="{{ (cardLayout | async)?.columns }}" rowHeight="200px">
    <mat-grid-tile *ngFor="let card of miniCards"
                   [colspan]="(cardLayout | async)?.miniCard.cols"
                   [rowspan]="(cardLayout | async)?.miniCard.rows"
                   class="mini-card">
      <app-mini-card [title]="card.title" [value]="card.value" [change]="card.change" [type]="card.type"></app-mini-card>
    </mat-grid-tile>
    <!--Charts-->
    <mat-grid-tile *ngFor="let i of [5]" 
                   [colspan]="(cardLayout | async)?.chart.cols"
                   [rowspan]="(cardLayout | async)?.chart.rows">
      <app-card title="Ventas del dia">
        <!-- <div class="dashboard-card-content">Ventas del dia</div> -->
        <app-line-chart></app-line-chart>
      </app-card>
    </mat-grid-tile>
    <mat-grid-tile *ngFor="let i of [6]" 
                   [colspan]="(cardLayout | async)?.chart.cols"
                   [rowspan]="(cardLayout | async)?.chart.rows">
      <app-card title="Ventas mensuales">
        <!-- <div class="dashboard-card-content">Ventas mensuales</div> -->
        <app-doughnut-chart></app-doughnut-chart>
      </app-card>
    </mat-grid-tile>    
  </mat-grid-list>
  <div class="dashboard-container">
    <mat-grid-list cols="{{ (cardLayout | async)?.columns }}" rowHeight="200px">
      <!-- Chart Card -->
      <mat-grid-tile [colspan]="(cardLayout | async)?.table.cols" 
                     [rowspan]="(cardLayout | async)?.table.rows">
        <app-card title="Ventas">
          <div class="dashboard-card-content">
            <app-bar-chart></app-bar-chart>
          </div>
        </app-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>