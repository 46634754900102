import { Component, OnInit } from "@angular/core";
import { DatosCliente } from "../../pedidos/nuevo-pedido/nuevo-pedido.component";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { flatMap, map } from "rxjs/operators";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from "@angular/material/legacy-autocomplete";
import { AsociarCliente } from "../../productos/cargar-producto/cargar-producto.component";
import { Router } from "@angular/router";
import { FacturaService } from "src/app/services/factura.service";
import { LoginService } from "src/app/services/login.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ProductosService } from "src/app/services/productos.service";
import swal from 'sweetalert2';

@Component({
    selector: 'app-crear-recibo-pago',
    templateUrl: './crear-recibo-pago.component.html',
    styleUrls: ['./crear-recibo-pago.component.scss']
  })
  export class CrearReciboPagoComponent implements OnInit {

    recibo: any = {
        razonSocial: "",
        cuit: 0,
        nroFactura: 0,
        nroCheque: 0,
        total: 0
      };

      datosCliente : DatosCliente = {
        razonSocial: "",
        clienteId: 0  
      }

  autocompleteControl = new UntypedFormControl();
  filtroOpcionesRazonSocial: Observable<DatosCliente[]> | any;
  razonSocialCliente: string = "";
  error: any;
  public formGroup: UntypedFormGroup;
  public total: number;
  public cuit: number
  public nroFactura: number
  public nroCheque: number

  constructor( private router: Router, private loginService: LoginService, public dialog: MatDialog, 
    public facturaService: FacturaService, public productosService: ProductosService, private formBuilder: UntypedFormBuilder, ) { }

      ngOnInit() {
        this.loadForm();         
          this.filtroOpcionesRazonSocial = this.autocompleteControl.valueChanges.pipe(
            map(value => typeof value === 'string' ? value : value?.razonSocial),
            flatMap(value => value ? this.filtroRazonSocial(value): [])
          );
    }

    displayRazonSocial(datosCliente: DatosCliente): string {
        return datosCliente ? datosCliente.razonSocial : '';
      }

      filtroRazonSocial(value: string): Observable <AsociarCliente[]> {
        const filterValue = value.toLowerCase();
    
        return this.productosService.filtrarRazonSocial(filterValue);
      }
    
      seleccionarRazonSocial(event: MatAutocompleteSelectedEvent):void {
        let razonSocial= event.option.value as DatosCliente;
        console.log(razonSocial);
        this.razonSocialCliente = razonSocial.razonSocial;
        this.datosCliente.razonSocial = razonSocial.razonSocial;
        this.datosCliente.clienteId = razonSocial.clienteId;
        event.option.focus();
        event.option.deselect();
      }

      cancel(){
        swal.fire({
          title:'¿Seguro deseas cancelar la carga?',
          icon: 'warning',
          showDenyButton: true,
          confirmButtonColor: '#3085d6',
          denyButtonColor: '#d33',
          denyButtonText: 'No',
          confirmButtonText: 'Si'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl('/pinturas-factory/dashboard');
            window.document.defaultView?.location.reload();
          }
        })   
      }
    
    
    loadForm() {
    
      this.formGroup = this.formBuilder.group({
        razonSocial: [{value:'', required: true}],
        cuit: [{value:'', required:true}],
        total: [{value:'', required: true}],
        nroCheque: [{value:'', required: true}],
        nroFactura: [{value:'', required: true}]    
      });
    }

    nuevoRecibo(){

    this.recibo.razonSocial = this.datosCliente.razonSocial;
    this.recibo.cuit = this.cuit;
    this.recibo.nroCheque = this.nroCheque;
    this.recibo.nroFactura = this.nroFactura;
    this.recibo.total = this.total
    // this.remito.productos = [];
    // for (let index = 0; index < this.itemsList.length; index++) {
    //   this.remito.productos.push({
    //     cantidad: this.itemsList[index].cantidad,
    //     precioUnitario: this.itemsList[index].precioUnitario,
    //     nombreProducto: this.itemsList[index].nombreProducto.nombreProducto,
    //     descripcion: this.itemsList[index].descripcion
    //   })
    // }
    console.log(this.recibo)
    this.facturaService.postRecibo(this.recibo).subscribe(
        (data: BlobPart) => {
          console.log('entro al imprimir');
          let blob = new Blob([data], {type: 'application/pdf'});
          const downloadURL = URL.createObjectURL(blob);
          window.open(downloadURL);
        },
        (error: any) => (
          this.error = error,
          console.log(this.error),
          console.log('se fue por el error'))
      );
      let timerInterval
       swal.fire({
         html: 'Generando recibo!',
         timer: 2000,
         timerProgressBar: true,
         didOpen: () => {
           swal.showLoading()
           const b = swal.getHtmlContainer().querySelector('b')
           timerInterval = setInterval(() => {
           }, 100)
         },
         willClose: () => {
           clearInterval(timerInterval)
         }
         }).then((result) => {
            if (result.dismiss === swal.DismissReason.timer) {
              swal.fire({
                title:'Se ha generado Recibo de Pago!',
                icon: 'success',
                confirmButtonColor: '##D2B4DE',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigateByUrl('/pinturas-factory/dashboard');
                  window.document.defaultView?.location.reload();
                }
              }) 
          }
       })
    }
  }