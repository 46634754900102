import { Component } from '@angular/core';
import {  ElementRef, AfterViewInit } from '@angular/core';
import * as d3 from 'd3';
@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements AfterViewInit {

  private margin = { top: 20, right: 20, bottom: 30, left: 50 };
  private width = 600 - this.margin.left - this.margin.right;
  private height = 300 - this.margin.top - this.margin.bottom;

  private data = [
    { date: new Date('2022-02-05'), valueA: 20, valueB: 10 },
    { date: new Date('2022-02-06'), valueA: 250, valueB: 160 },
    { date: new Date('2022-02-07'), valueA: 300, valueB: 200 },
    { date: new Date('2022-02-08'), valueA: 350, valueB: 240 },
    { date: new Date('2022-02-09'), valueA: 400, valueB: 280 },
    { date: new Date('2022-02-10'), valueA: 470, valueB: 30 },
    { date: new Date('2022-02-11'), valueA: 500, valueB: 360 }
  ];

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    this.createChart();
  }

  private createChart(): void {
    const element = this.elementRef.nativeElement.querySelector('.chart');
    const svg = d3.select(element)
      .append('svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    const xScale = d3.scaleTime()
      .domain(d3.extent(this.data, d => d.date) as [Date, Date])
      .range([0, this.width]);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(this.data, d => Math.max(d.valueA, d.valueB)) as number])
      .range([this.height, 0]);

    const lineA = d3.line<{ date: Date, valueA: number }>()
      .x(d => xScale(d.date))
      .y(d => yScale(d.valueA));

    const lineB = d3.line<{ date: Date, valueB: number }>()
      .x(d => xScale(d.date))
      .y(d => yScale(d.valueB));

    const xAxis = d3.axisBottom(xScale).tickFormat(d3.timeFormat('%d-%b') as any);
    const yAxis = d3.axisLeft(yScale);

    svg.append('g')
      .attr('transform', `translate(0, ${this.height})`)
      .call(xAxis);

    svg.append('g')
      .call(yAxis);

    svg.append('path')
      .datum(this.data)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 2)
      .attr('d', lineA);

    svg.append('path')
      .datum(this.data)
      .attr('fill', 'none')
      .attr('stroke', 'purple')
      .attr('stroke-width', 2)
      .attr('d', lineB);

    // Add points
    svg.selectAll('.dotA')
      .data(this.data)
      .enter().append('circle')
      .attr('class', 'dotA')
      .attr('cx', d => xScale(d.date))
      .attr('cy', d => yScale(d.valueA))
      .attr('r', 3)
      .attr('fill', 'steelblue');

    svg.selectAll('.dotB')
      .data(this.data)
      .enter().append('circle')
      .attr('class', 'dotB')
      .attr('cx', d => xScale(d.date))
      .attr('cy', d => yScale(d.valueB))
      .attr('r', 3)
      .attr('fill', 'purple');
  }
}


