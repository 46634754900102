import { Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { Router } from "@angular/router";
import { LoginService } from "src/app/services/login.service";
import { DatosCliente, ItemPedido, Nombre } from "../../pedidos/nuevo-pedido/nuevo-pedido.component";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { flatMap, map } from "rxjs/operators";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from "@angular/material/legacy-autocomplete";
import { AsociarCliente } from "../../productos/cargar-producto/cargar-producto.component";
import { PedidoService } from "src/app/services/pedido.service";
import { ProductosService } from "src/app/services/productos.service";
import swal from 'sweetalert2';
import { FacturaService } from "src/app/services/factura.service";

@Component({
    selector: 'app-crear-remito',
    templateUrl: './crear-remito.component.html',
    styleUrls: ['./crear-remito.component.scss']
  })
  export class CrearRemitoComponent implements OnInit {

    datosCliente : DatosCliente = {
        razonSocial: "",
        clienteId: 0  
      }

      items : ItemPedido = {
        nombreProducto: {
          productoId: 0,
          nombreProducto: ''
        },
        descripcion: "",
        cantidad: 0,
        precioUnitario: 0
       }

       nombre : Nombre = {
        productoId: 0,
        nombreProducto: ''
       }

       remito: any = {
        productos:[{
          cantidad:0,
          idProductoFinal:0,
          precioUnitario: 0
        }],
        razonSocial: "",
        cuit: 0,
        iva: ""
      };
  
  public dataSource = new MatTableDataSource<ItemPedido>();
  public ELEMENT_DATA: ItemPedido[] = [];
  public formGroup: UntypedFormGroup;
  public cantidad : number;
  public precioUnitario : number;
  public total: number;
  public iva: string;
  public cuit: number

  displayedColumns: string[] = [  'nombreProducto', 'descripcion', 'cantidad', 'precioUnitario', 'total','accion'];
  autocompleteControl = new UntypedFormControl();
  autocompleteControlProducto = new UntypedFormControl();
  filtroOpcionesNombre: Observable<ItemPedido[]> | any;
  filtroOpcionesRazonSocial: Observable<DatosCliente[]> | any;
  nombreProduc: string = "";
  razonSocialCliente: string = "";
  itemsList: ItemPedido[] = [];
  error: any;
  
    constructor( private router: Router, private loginService: LoginService, public dialog: MatDialog, 
        public facturaService: FacturaService, public productosService: ProductosService, private formBuilder: UntypedFormBuilder, ) { }
  
    ngOnInit() {
        this.loadForm();
        this.filtroOpcionesNombre = this.autocompleteControlProducto.valueChanges.pipe(
            map(value => typeof value === 'string' ? value : value.nombreProducto),
            flatMap(value => value ? this.filtroNombre(value): [])
            );          
          this.filtroOpcionesRazonSocial = this.autocompleteControl.valueChanges.pipe(
            map(value => typeof value === 'string' ? value : value.razonSocial),
            flatMap(value => value ? this.filtroRazonSocial(value): [])
          );
    }

    displayNombre(nombre: Nombre): string {
        return nombre ? nombre.nombreProducto: '';
      }
    
      displayRazonSocial(datosCliente: DatosCliente): string {
        return datosCliente ? datosCliente.razonSocial : '';
      }
    
      filtroNombre(value: string): Observable <Nombre[]> {
        const filterValue = value.toLowerCase();
        console.log("datos cliente")
        return this.productosService.filtrarAllProductos(filterValue);
      }
    
      filtroRazonSocial(value: string): Observable <AsociarCliente[]> {
        const filterValue = value.toLowerCase();
    
        return this.productosService.filtrarRazonSocial(filterValue);
      }
    
      seleccionarRazonSocial(event: MatAutocompleteSelectedEvent):void {
        let razonSocial= event.option.value as DatosCliente;
        console.log(razonSocial);
        this.razonSocialCliente = razonSocial.razonSocial;
        this.datosCliente.razonSocial = razonSocial.razonSocial;
        this.datosCliente.clienteId = razonSocial.clienteId;
        event.option.focus();
        event.option.deselect();
      }
    
      seleccionarNombre(event: MatAutocompleteSelectedEvent):void {
        let nombreProducto= event.option.value as Nombre;
        console.log(nombreProducto);
        this.nombreProduc = nombreProducto.nombreProducto;
        event.option.focus();
        event.option.deselect();
      }

      addNew(){
        if(this.formGroup.invalid){
          return
        };
        console.log("los items ids");
        console.log(this.items);
        this.ELEMENT_DATA.push(this.items);
        this.itemsList.push(this.items);
        console.log("los items de items list");
        console.log(this.items);
        this.dataSource.data= this.ELEMENT_DATA;
        this.items = {
        nombreProducto: {
          productoId: 0,
          nombreProducto: ''
        },
        descripcion: "",
        cantidad: 0,
        precioUnitario: 0
        };
    
      }
    
      borrarFila(items: any) {
        if (confirm("¿Seguro desea borrar el insumo agregado?")) {
            this.ELEMENT_DATA.forEach((value,index)=>{
            if(value.nombreProducto.productoId==items.nombreProducto.productoId) 
            this.ELEMENT_DATA.splice(index,1);
            });
            this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        }
      }

    
      calcularTotal(items: ItemPedido) {
        let totalValue  = items.cantidad * items.precioUnitario;
        return  totalValue.toFixed();
      }

    cancel(){
        swal.fire({
          title:'¿Seguro deseas cancelar la carga?',
          icon: 'warning',
          showDenyButton: true,
          confirmButtonColor: '#3085d6',
          denyButtonColor: '#d33',
          denyButtonText: 'No',
          confirmButtonText: 'Si'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl('/pinturas-factory/dashboard');
            window.document.defaultView.location.reload();
          }
        })   
      }
    
    
    loadForm() {
    
      this.formGroup = this.formBuilder.group({
        razonSocial: [{value:'', required: true}],
        iva: [{ value: '', required: true }],
        cuit: [{value:'', required:true}],
        // fechaFin: [{value:'', required: true}],
        nombreProducto: [{value:'', required: true}],
        descripcion: [{value:'', required: true}],
        cantidad: [{value:'', required: true}],
        precioUnitario: [{value:'', required: true}]
    
      });
    }

    nuevoRemito(){

    this.remito.razonSocial = this.datosCliente.razonSocial;
    this.remito.cuit = this.cuit;
    this.remito.iva = this.iva;
    this.remito.productos = [];
    for (let index = 0; index < this.itemsList.length; index++) {
      this.remito.productos.push({
        cantidad: this.itemsList[index].cantidad,
        precioUnitario: this.itemsList[index].precioUnitario,
        nombreProducto: this.itemsList[index].nombreProducto.nombreProducto,
        descripcion: this.itemsList[index].descripcion
      })
    }
    console.log(this.remito)
    this.facturaService.postRemito(this.remito).subscribe(
        (data: BlobPart) => {
          console.log('entro al facturar');
          let blob = new Blob([data], {type: 'application/pdf'});
          const downloadURL = URL.createObjectURL(blob);
          window.open(downloadURL);
        //   this.facturaParcial.productoItemDTO = [];
        //   this.factura.items = [];
        //   this.deshabilitarFacturar = false;
        },
        (error: any) => (
          this.error = error,
          console.log(this.error),
          console.log('se fue por el error'))
      );
      let timerInterval
       swal.fire({
         html: 'Generando remito!',
         timer: 2000,
         timerProgressBar: true,
         didOpen: () => {
           swal.showLoading()
           const b = swal.getHtmlContainer().querySelector('b')
           timerInterval = setInterval(() => {
           }, 100)
         },
         willClose: () => {
           clearInterval(timerInterval)
         }
         }).then((result) => {
            if (result.dismiss === swal.DismissReason.timer) {
              swal.fire({
                title:'Se ha generado remito!',
                icon: 'success',
                confirmButtonColor: '##D2B4DE',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigateByUrl('/pinturas-factory/dashboard');
                  window.document.defaultView.location.reload();
                }
              }) 
          }
       })
    }
}