import { Component } from '@angular/core';
import {  OnInit, ElementRef } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit{
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.createChart();
  }

  private createChart(): void {
    const data = [10, 20, 30, 40, 50];
    const width = 300;
    const height = 300;
    const margin = 40;
    const radius = Math.min(width, height) / 2 - margin;

    const svg = d3.select(this.elementRef.nativeElement.querySelector('.chart'))
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const pie = d3.pie()
      .value((d: any) => d);

    const data_ready = pie(data);

    const arc = d3.arc<d3.PieArcDatum<number>>()
      .innerRadius(radius * 0.5)
      .outerRadius(radius * 0.8);

    svg.selectAll('path')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', arc as any)  // Cast to any to avoid type issues
      .attr('fill', (d: any) => color(d.data))
      .attr('stroke', 'white')
      .style('stroke-width', '2px')
      .style('opacity', 0.7);

    // Adding animation
    svg.selectAll('path')
      .transition()
      .duration(750)
      .attrTween('d', function(d: any) {
        const i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function(t: any) {
          d.endAngle = i(t);
          return arc(d as any) as any;  // Cast to any to avoid type issues
        };
      });
  }
}